@use "../../vars";

.application-table-container {
  .jira-id-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .issue-type-badges {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .issue-type-badge {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        font-weight: bold;

        &.Task {
          background-color: rgba(58, 169, 217, 0.2);
          color: #3aa9d9;
        }

        &.Bug {
          background-color: rgba(253, 68, 51, 0.2);
          color: #fd4433;
        }

        &.Epic {
          background-color: rgba(101, 84, 192, 0.2);
          color: #6554C0;
        }

        &.Story {
          background-color: rgba(30, 185, 128, 0.2);
          color: #1EB980;
        }

        &.Other {
          background-color: rgba(128, 128, 128, 0.2);
          color: grey;
        }
      }
    }
  }

  .expand-collapse-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mainTextColor);
    transition: color 0.2s ease;

    &:hover {
      color: var(--mainTextColor);
      opacity: 0.8;
    }

    svg {
      font-size: 0.8rem;
    }
  }

  .expand-cell {
    width: 48px;
    padding-left: 16px !important;
    padding-right: 8px !important;
  }

  .expanded-row {
    .expanded-cell {
      background-color: vars.$hover-table-row;
    }

    .exception-details {
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;


      .condition-item {
        border: 1px solid vars.$mainColor;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 0.95rem;

        .operator-text {
          font-weight: 600;
          margin: 0 4px;
        }
      }

      .condition-separator {
        font-weight: bold;
        color: vars.$secondary-text-color;
        font-size: 0.9rem;
      }
    }
  }

  .feedback-stats {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-right: 0.5rem;
    background-color: vars.$secondaryColor;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 0.9rem;
    white-space: nowrap;
    font-weight: bold;

    span {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      color: var(--mainTextColor);

      svg {
        font-size: 1rem;
      }

      &:nth-child(1) svg {
        color: #1EB980;
      }

      &:nth-child(2) svg {
        color: #fd4433;
      }

      &:nth-child(3) svg {
        color: #FFCF44;
      }

      &:nth-child(4) svg {
        color: vars.$mainColor;
      }
    }
  }

  .add-threat-model-btn {
    background-color: vars.$accentColor;
    color: white;
    border: none;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem 0.875rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    margin-right: 0.5rem;

    &.filters-active {
      border: 1px solid vars.$mainColor;
    }

    svg {
      font-size: 1rem;
    }

    &:hover {
      background-color: vars.$accent-color-dark;
    }
  }
}

.action-buttons {
  display: flex;
  gap: 9px;

  .delete-model-btn,
  .view-model-btn {
    color: var(--mainTextColor) !important;

    &:hover {
      color: vars.$mainColor !important;
    }
  }
}

.feedback-status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  font-weight: 700;
  gap: 0.25rem;

  &.Liked {
    background-color: rgba(30, 185, 128, 0.2);
    color: #1EB980;
  }

  &.Disliked {
    background-color: rgba(253, 68, 51, 0.2);
    color: #fd4433;
  }

  &.Neutral {
    background-color: rgba(128, 128, 128, 0.2);
    color: grey;
  }

  svg {
    margin-right: 0.25rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease-in-out;
}

.modal-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;
  min-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-close {
  position: absolute;
  top: -2rem;
  right: 0;
  background: none;
  border: none;
  color: var(--mainTextColor);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  z-index: 1002;
  padding: 0;
  line-height: 1;

  &:hover {
    color: vars.$mainColor;
  }
}

.modal-content {
  width: 100% !important;
  min-width: unset;
  max-width: 1200px;
  background-color: vars.$hover-table-row;
  color: var(--mainTextColor);
  padding: 2.5rem;
  border-radius: 12px;
  overflow-y: auto;
  animation: modalFadeIn 0.3s ease-out;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
  }
}

.add-threat-model-modal,
.regenerate-feedback-modal {
  width: 700px !important;
  max-width: 90% !important;
  min-width: unset !important;
}

.add-threat-model-modal {
  width: 600px;
  max-width: 90%;

  h3 {
    margin: -0.5rem 0 1.5rem;
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .icon {
      font-size: 1.5rem;
      color: vars.$mainColor;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;

    label {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      display: block;
      color: var(--mainTextColor);
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 0.75rem;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background-color: var(--secondaryElementsColor);
      color: var(--mainTextColor);
      font-size: 0.95rem;
      line-height: 1.5;
      transition: all 0.2s ease;

      &:focus {
        border-color: rgba(114, 222, 255, 0.5);
        outline: none;
        box-shadow: 0 0 0 3px rgba(114, 222, 255, 0.1);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &::placeholder {
        font-size: 0.95rem;
        color: vars.$secondary-text-color;
      }
    }

    textarea {
      min-height: 120px;
      resize: vertical;
    }
  }

  .error-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    animation: fadeIn 0.3s ease-out;
    background-color: rgba(253, 68, 51, 0.1);
    border: 1px solid rgba(253, 68, 51, 0.3);
    color: #fd4433;
  }

  .success-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    animation: fadeIn 0.3s ease-out;
    background-color: rgba(30, 185, 128, 0.1);
    border: 1px solid rgba(30, 185, 128, 0.3);
    color: #1EB980;
  }
}

.regenerate-feedback-modal {
  width: 600px;
  max-width: 90%;

  h3 {
    margin: -0.5rem 0 1.5rem;
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    .icon {
      font-size: 1.5rem;
      color: vars.$mainColor;
    }
  }

  .status-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    animation: fadeIn 0.3s ease-out;

    &.success {
      background-color: rgba(30, 185, 128, 0.1);
      border: 1px solid rgba(30, 185, 128, 0.3);
      color: #1EB980;

      svg {
        font-size: 1.25rem;
      }
    }
  }
}

// Common modal button styles
.add-threat-model-modal,
.regenerate-feedback-modal {
  .button-group {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    button {
      padding: 0.5rem 0.875rem;
      border-radius: 8px;
      gap: 6px;
      font-weight: bold;
      font-size: 0.875rem;
      transition: all 0.2s ease;
      min-width: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &.cancel-btn {
        background-color: vars.$secondary-color-dark;
        color: vars.$main-text-color;
        border: none;

        &:hover {
          background-color: vars.$hover-dark-button;
        }
      }

      &.submit-btn {
        display: flex;
        align-items: center;
        background-color: vars.$accentColor;
        border: 1px solid transparent;
        color: white;

        &:hover:not(:disabled) {
          background-color: vars.$accent-color-dark;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.column-menu-paper {
  padding: 1rem;
  background: var(--secondaryElementsColor) !important;

  .filter-section {
    h3 {
      color: var(--mainTextColor);
      margin: 0 0 1rem;
      font-size: 1rem;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      gap: 2px;
      color: var(--mainTextColor);

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        accent-color: vars.$accentColor;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .required-column {
        opacity: 0.7;

        .required-indicator {
          color: vars.$mainColor;
          font-weight: bold;
        }
      }
    }
  }
}

// Update search bar layout
.application-table-search {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .add-threat-model-btn {
    // Existing styles
    margin-right: 0;
  }
}

// Add responsive adjustments
@media (max-width: 768px) {
  .application-table-search {
    flex-direction: column;

    .feedback-stats {
      order: -1;
      width: 100%;
      justify-content: center;
    }

    .add-threat-model-btn {
      width: 100%;
      justify-content: center;
    }

    .MuiTextField-root {
      width: 100%;
    }
  }
}

.column-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .filter-actions {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .filter-icon {
      color: vars.$mainColor;
      padding: 2px;

      &.active {
        opacity: 1;
        color: vars.$mainColor;
      }
    }

    .clear-filter-icon {
      color: vars.$mainColor;
      padding: 2px;
    }

    .copy-all-emails-icon {
      color: vars.$mainColor;
      margin-right: 5px;
    }
  }
}

.clear-filters-section {
  padding: 0px 15px;

  .clear-all-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    padding: 0.5rem 0.875rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    border: 1px solid vars.$accentColor;
    background-color: transparent;
    color: vars.$main-text-color;

    &:hover:not(:disabled) {
      background: vars.$accent-color-dark;
      border: 1px solid vars.$accent-color-dark;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    svg {
      font-size: 16px;
    }
  }
}

.column-menu-paper {
  background-color: vars.$secondaryColor !important;
  border-radius: 15px !important;
  box-shadow: none !important;
  min-width: 200px !important;
  padding: 10px 0;
  margin-top: 10px;

  .filter-section {
    h3 {
      color: var(--mainTextColor);
      font-size: 14px;
      margin: 0;
      padding: 5px 15px;
      font-weight: normal;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      gap: 6px;

      &:hover {
        background-color: vars.$secondary-color-dark !important;
      }

      input[type="checkbox"] {
        margin-right: 8px;
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #fff;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        position: relative;

        &:checked {
          background-color: vars.$accentColor;
          border-color: vars.$accentColor;

          &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 6px;
            width: 4px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      span {
        color: var(--mainTextColor);
        font-size: 14px;

        &.required-column {
          opacity: 0.7;
        }

        .required-indicator {
          color: vars.$accentColor;
        }
      }
    }
  }
}

.filter-actions {
  display: flex;
}

.MuiIconButton-root {
  color: var(--mainTextColor) !important;

  &:hover {
    color: vars.$mainColor !important;
  }

  &+.MuiIconButton-root {
    margin-left: 5px;
  }
}

.filter-icon {
  &.active {
    color: vars.$accentColor !important;
  }
}

.clear-filter-icon {
  padding: 3px !important;
  font-size: 0.8rem !important;
}