@use "../../../vars";

.selectall-multiselect {
    input {
        outline: none !important;
        border: none !important;
        opacity: 0 !important;
    }

    input:focus {
        outline: none !important;
        border: none !important;
        opacity: 1 !important;
    }

    color: black;

    div[class*="control"] {
        border-radius: 24px !important;
        background-color: vars.$secondary-color-dark !important;
        border-color: transparent !important;
    }

    .react-select {
        &__menu {
            z-index: 1000;
        }
      }
}