:root {
    --mainColor: #92F2CC;
    --secondaryColor: #444444;
    --accentColor: #4b4c61;
    --bodyBg: #32333D;
    --bodyColor: #eee;
    --mainTextColor: #eee;
    --secondaryTextColor: #9E9E9E;
    --linkColor: #92F2CC;
    --hoverLinkColor: #1E7168;
    --secondaryElementsColor: #373740;
    --hoverTableRow: #41424D;
    --accentColorDark: #454659;
    --secondaryColorDark: #3f3f3f;
    --hoverDarkButton: #383838;
    --secondaryColorDarker: #d9dee3;
    --navbarBg: rgba(55, 55, 64, 0.85);
  }
  
  // Light theme override
  [data-theme='light'] {
    --mainColor: #08bca7;
    --secondaryColor: #E9ECEF;
    --accentColor: #457B9D;
    --bodyBg: #F8F9FA;
    --bodyColor: #212529;
    --mainTextColor: #212529;
    --secondaryTextColor: #6C757D;
    --linkColor: #08bca7;
    --hoverLinkColor: #1E7168;
    --secondaryElementsColor: #FFFFFF;
    --hoverTableRow: #E9ECEF;
    --accentColorDark: #3d6d8d;
    --secondaryColorDark: #dde2e6;
    --hoverDarkButton: #d4d4d4;
    --secondaryColorDarker: #3a3a3a;
    --navbarBg: rgba(255, 255, 255, 0.85);
  }
  
  // Variables for SCSS usage
  $mainColor: var(--mainColor);
  $secondaryColor: var(--secondaryColor);
  $accentColor: var(--accentColor);
  $body-bg: var(--bodyBg);
  $body-color: var(--bodyColor);
  $main-text-color: var(--mainTextColor);
  $secondary-text-color: var(--secondaryTextColor);
  $link-color: var(--linkColor);
  $hover-link-color: var(--hoverLinkColor);
  $secondary-elements-color: var(--secondaryElementsColor);
  $hover-table-row: var(--hoverTableRow);
  $accent-color-dark: var(--accentColorDark);
  $secondary-color-dark: var(--secondaryColorDark);
  $hover-dark-button: var(--hoverDarkButton);
  $secondary-color-darker: var(--secondaryColorDarker);