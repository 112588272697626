@use "../vars";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&display=swap');

.sidenav-container {
  height: 100vh;
  width: 84px;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.75rem;
  background-color: var(--navbarBg);
  backdrop-filter: blur(8px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;

  &:hover {
    width: 280px;

    .nav-text {
      opacity: 1;
      transform: translateX(0);
      color: vars.$main-text-color;
    }
  }
}

.nav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1rem;
  width: 280px;
}

.settings-container {
  margin-top: auto;
  padding-top: 1rem;
  width: 280px;
}

.nav-item {
  display: flex;
  position: relative;

  &.logo {
    margin-bottom: 2.5rem;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 14px;
  text-decoration: none;
  color: vars.$secondary-text-color;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 4px;
    height: 0;
    background-color: vars.$mainColor;
    border-radius: 0 4px 4px 0;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    color: vars.$main-text-color;
    background-color: vars.$hover-table-row;

    &::before {
      height: 16px;
      opacity: 1;
      top: calc(50% - 8px);
    }
  }

  &.active {
    color: vars.$mainColor;
    background-color: vars.$hover-table-row;

    &::before {
      height: 60%;
      opacity: 1;
      top: 20%;
    }
  }

  &.logo-link {
    font-size: 1.4em;
    color: vars.$mainColor;
    font-weight: bold;
    font-family: 'IBM Plex Mono', monospace;
    text-transform: uppercase;

    &:hover {
      background: none;
      &::before {
        display: none;
      }
    }

    &.active {
      background: none;

      &::before {
        display: none;
      }
    }
  }
}

.logo-icon {
  font-size: 1.8em;
  margin-right: 0.75rem;
  transition: transform 0.3s ease;
}

.nav-icon {
  font-size: 1.5em;
  margin-right: 1rem;
  transition: transform 0.3s ease;
  min-width: 1.5em;
}

.nav-text {
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 1.1em;
}