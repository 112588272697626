@use "../../vars";

.add-application-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    transition: all 0.3s ease-in-out;
    padding: 0;
    overflow-y: auto;
}

.add-application-modal {
    &.full-screen {
        width: 100% !important;
        height: 100vh;
        max-width: 100% !important;
        min-width: unset !important;
        margin: 0;
        border-radius: 0;
        display: flex;
        flex-direction: column;
    }

    .close-btn {
        position: fixed;
        top: 2rem;
        right: 2rem;
        background: transparent;
        border: none;
        color: var(--mainTextColor);
        font-size: 2.5rem;
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        transition: all 0.2s ease;
        z-index: 100;

        &:hover {
            color: var(--mainTextColor);
            opacity: 0.8;
        }
    }

    .wizard-nav {
        padding: 0 0 2rem;
        max-width: 800px;
        margin: 0 auto;
        width: 100%;
        position: relative;

        .wizard-header {
            padding: 1.5rem 0;
            margin-bottom: 3rem;
            width: 100%;

            .header-content {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;

                .icon {
                    font-size: 1.75rem;
                    color: vars.$mainColor;
                }

                h1 {
                    font-size: 2rem;
                    font-weight: bold;
                    margin: 0;
                    color: var(--mainTextColor);
                }
            }
        }

        .steps-indicator {
            display: flex;
            margin: 0 auto;
            position: relative;
            justify-content: space-between;
            width: 100%;

            .step {
                position: relative;
                flex: 1;
                display: flex;
                justify-content: center;
                color: vars.$secondary-text-color;
                transition: all 0.3s ease;

                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    left: calc(50% + 1.25rem + 20px);
                    top: 1.25rem;
                    width: calc(100% - 2.5rem - 40px);
                    height: 0.5px;
                    background: vars.$secondary-text-color;
                    transform: translateY(-50%);
                }

                .step-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.75rem;

                    .step-number {
                        width: 2.5rem;
                        height: 2.5rem;
                        border-radius: 50%;
                        background-color: vars.$secondary-color-dark;
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.1rem;
                        font-weight: bold;
                        transition: all 0.3s ease;
                    }

                    .step-title {
                        font-weight: bold;
                        font-size: 1.0rem;
                        letter-spacing: 0.5px;
                        color: vars.$secondary-text-color;
                        text-align: center;
                    }
                }

                &.active {
                    color: var(--mainTextColor);

                    .step-number {
                        background: vars.$accentColor;
                        border-color: transparent;
                        color: white;
                    }

                    .step-title {
                        color: var(--mainTextColor);
                    }
                }

                &.completed .step-number {
                    background: #1EB980;
                    border-color: transparent;
                    color: white;
                }
            }
        }
    }

    form {
        flex: 1;
        display: flex;
        flex-direction: column;

        .wizard-content {
            flex: 1;
            padding: 3rem 0;
            max-width: 800px;
            margin: 0 auto;
            width: 100%;

            .step-content {
                animation: fadeSlideUp 0.4s ease;

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }

                .step-description {
                    color: vars.$secondary-text-color;
                    margin-bottom: 1.5rem;
                    font-size: 1.1rem;
                    line-height: 1.5;
                }
            }
        }
    }

    .form-group {
        margin-bottom: 1.0rem;

        label {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 0.5rem;
            display: block;
            color: var(--mainTextColor);

            &.required {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                .required-indicator {
                    color: #ff4d4f;
                    margin-left: 2px;
                }
            }
        }

        input,
        textarea {
            width: 100%;
            padding: 0.5rem;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background-color: vars.$secondary-elements-color;
            color: var(--mainTextColor);
            font-size: 0.95rem;
            line-height: 1.5;
            transition: all 0.2s ease;

            &:focus {
                border-color: rgba(114, 222, 255, 0.5);
                outline: none;
                box-shadow: 0 0 0 3px rgba(114, 222, 255, 0.1);
            }

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &::placeholder {
                font-size: 0.95rem;
                color: vars.$secondary-text-color;
            }
        }
    }

    .button-group {
        max-width: 800px;
        margin: 2rem auto;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        button {
            padding: 0.5rem 0.875rem;
            border-radius: 8px;
            gap: 6px;
            font-weight: bold;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            min-width: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &.cancel-btn {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }

            &.submit-btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: vars.$accentColor;
                border: none;
                color: white;

                &:hover:not(:disabled) {
                    background-color: vars.$accent-color-dark;
                }
            }

            &.previous-btn {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }
        }
    }

    .error-message,
    .success-message {
        padding: 0.75rem 1rem;
        border-radius: 8px;
        margin-top: 1rem;

        div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    .error-message {
        background: rgba(255, 77, 79, 0.1);
        border: 1px solid rgba(255, 77, 79, 0.2);
        color: #ff4d4f;
    }

    .success-message {
        background-color: rgba(30, 185, 128, 0.1);
        border: 1px solid rgba(30, 185, 128, 0.3);
        color: #1EB980;
    }

    @keyframes fadeSlideUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}