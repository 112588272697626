.audit-log{
    .filter{
        button{
            float: right;
        }

        
    }
}

#table-loader{
    padding-top: 50px;
}