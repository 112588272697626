@use "vars";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  width: 100vw;
  height: 100vh;

  background-color: vars.$body-bg;
  color: vars.$main-text-color;
}

a {
  color: vars.$link-color;
  text-decoration: none;

  &:hover {
    color: vars.$hover-link-color;
  }
}

.btn-with-icon {
  display: flex;
  align-items: center;
}

input[type="text"] {
  background: vars.$secondaryColor;
  outline: none;
  border: none;
  padding: 0.5rem;
  color: var(--mainTextColor);
}

input[type="submit"],
input[type="button"] {
  background: #444;
  outline: none;
  border: none;
  padding: 0.5rem;

  color: vars.$main-text-color;
  cursor: pointer;

  &:hover {
    color: vars.$mainColor;
  }
}


th {
  text-align: left;
}

th,
td {
  padding: 0.25rem 0.5rem;
}

.btn-main-inline {
  color: vars.$mainColor;
}

.wrap-btn-create {
  padding: 0.5rem 1.0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.action-delete {
  padding: 0.24rem;
}

.action {
  font-size: 1.5rem;
  padding: 0.24rem;

  &:hover {
    color: vars.$mainColor;
    cursor: pointer;
  }
}

.validation-failures {
  color: red;
}

.multiselect-checkbox-wrap {
  display: flex;
  flex-direction: column;
}

// Metrics
.statistic-pane {
  background-color: vars.$secondary-elements-color;
  padding: 20px;
  border-radius: 1rem;
}

// Table styles
.application-table {
  &-container {
    max-width: 100vw;
  }

  &-search {
    margin-bottom: 1.0rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .MuiTextField-root {
      width: 300px;

      .MuiOutlinedInput-root {
        border-radius: 8px;
        background-color: vars.$secondaryColor;
        overflow: hidden;
        height: 41px;

        input::placeholder {
          color: var(--mainTextColor)
        }

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: vars.$mainColor;
        }

        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: vars.$mainColor;
        }
      }

      .MuiInputAdornment-root {
        .search-icon, .clear-icon {
          color: vars.$secondary-text-color;
        }
      }
    }
  }

  &-paper.MuiPaper-root {
    border-radius: 15px !important;
    background-color: vars.$secondary-elements-color !important;
  }

  &-actions {
    display: flex;
    gap: 0.5rem;

    .MuiIconButton-root {
      color: var(--mainTextColor) !important;
      transition: background-color 0.2s ease, transform 0.2s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &-pagination {
    color: var(--mainTextColor) !important;

    .MuiTablePagination-toolbar {
      align-items: center;

      .MuiTablePagination-selectLabel {
        margin: 0;
        display: flex;
        align-items: center;
        color: var(--mainTextColor);
      }
    }

    .MuiTablePagination-select {
      border-radius: 20px;
      background-color: vars.$secondaryColor;
      color: var(--mainTextColor);
    }

    .MuiTablePagination-displayedRows {
      margin: 0;
      color: var(--mainTextColor);
      margin-right: -0.5rem;
    }

    .MuiTablePagination-actions {
      .MuiIconButton-root {
        color: var(--mainTextColor);
        background-color: vars.$secondaryColor;
        margin: 0 2px;

        &:hover {
          background-color: vars.$secondary-color-dark;
        }

        &.Mui-disabled {
          opacity: 0.5;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

// Additional global overrides to ensure styles are applied
.MuiTable-root {
  .MuiTableHead-root {
    background-color: vars.$accentColor !important;
    color: var(--mainTextColor) !important;

    .MuiTableRow-root {
      background-color: vars.$accentColor !important;
      color: var(--mainTextColor) !important;

      .MuiTableCell-head {
        background-color: vars.$accentColor !important;
        color: #fff !important;
        font-size: 0.95rem !important;
        font-weight: 700 !important;
        text-transform: uppercase !important;
        border-bottom: none;

        .MuiTableSortLabel-root {
          &:hover {
            color: vars.$mainColor !important;
          }

          &.Mui-active {
            color: vars.$mainColor !important;

            .MuiTableSortLabel-icon {
              color: vars.$mainColor !important;
            }
          }
        }

        .MuiTableSortLabel-icon {
          color: vars.$mainColor !important;
          transition: color 0.2s ease !important;
        }
      }
    }

    .checkbox-container {
      input[type="checkbox"] {
        &:not(:checked) {
          border-color: white;
        }

        &:checked {
          background-color: vars.$accent-color-dark;
          border-color: vars.$accent-color-dark;
        }
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-body {
      color: var(--mainTextColor);
      font-size: 1rem !important;
      border-bottom: 1px solid vars.$hover-table-row !important;
    }

    .MuiTableRow-root {
      .MuiTableCell-body {
        &:last-child {
          border-right: none !important;
        }
      }
    }
  }
}

.column-header {
  display: inline-flex;
  align-items: center;

  .filter-icon.MuiIconButton-root {
    padding: 0;
    margin-left: -4px;
    color: white !important;

    &:hover {
      color: vars.$mainColor !important;
    }

    &.active {
      color: vars.$mainColor !important;
    }
  }

  .clear-filter-icon.MuiIconButton-root {
    padding: 0;
    margin-left: 4px;
    color: white !important;

    &:hover {
      color: vars.$mainColor !important;
    }
  }

  .copy-all-emails-icon.MuiIconButton-root {
    padding: 0;
    color: white !important;

    &:hover {
      color: vars.$mainColor !important;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .application-table {
    &-container {
      padding: 1rem;
    }

    &-search {
      .MuiTextField-root {
        width: 100%;
      }
    }

    &-paper {
      border-radius: 12px !important;
    }
  }
}

// Progress bar styles
.progress-container {
  margin: 1.5rem 0;
  animation: fadeIn 0.3s ease-out;

  .progress-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: var(--mainTextColor);
  }

  .progress-bar {
    height: 8px;
    background-color: var(--secondaryElementsColor);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    
    .progress-bar-fill {
      height: 100%;
      background-color: #1EB980;
      border-radius: 4px;
      transition: width 0.3s ease-in-out;
      position: relative;
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.application-table-link {
  &:hover {
    color: var(--hoverLinkColor);
  }
}