@use "../../vars";

.status-indicators {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .status-dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        font-weight: bold;

        &.active {
            background-color: rgba(30, 185, 128, 0.2);
            color: #1EB980;
        }

        &.draft {
            background-color: rgba(128, 128, 128, 0.2);
            color: grey;
        }
    }
}

.status-text {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: bold;

    &.active {
        background-color: rgba(30, 185, 128, 0.2);
        color: #1EB980;
    }

    &.inactive {
        background-color: rgba(253, 68, 51, 0.2);
        color: #fd4433;
    }
}