@use "../vars";

#main {
  padding: 1.0rem;
  width: 100%;
}

body{
  width: 98vw;
}

.btn-main
{
  padding: 0.5rem 1rem;
  outline: 1px solid vars.$mainColor;
  color: vars.$mainColor;
}