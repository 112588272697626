@use "../../vars";

.modal-content.exceptions {
  max-height: 90vh;
  overflow-y: auto;
  width: 600px;
  max-width: 90%;
}

.form-group.conditions {
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: vars.$secondary-elements-color;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .condition-row {
    margin-top: 8px;
  }

  .delete-condition {
    position: absolute;
    top: 10px;
    right: 10px;
    color: vars.$main-text-color;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover:not(:disabled) {
      color: #fd4433;
    }
  }
}

.dropdown-group {
  display: flex;
  flex-direction: column;
  flex: 1;

  .dropdown-label {
    font-size: 12px;
    color: vars.$secondary-text-color;
    margin-bottom: 4px;
    font-weight: 500;
  }

  select {
    width: 100%;
  }
}

.comparison-dropdown {
  flex: 0.8;
  min-width: 100px;
  max-width: 120px;
}

.action-dropdown {
  flex: 0.5;
  min-width: 90px;
}

.condition-row,
.action-row {
  display: flex;
  gap: 12px;
  align-items: flex-end;

  input {
    flex: 1;
  }

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-bottom: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
    color: vars.$main-text-color;

    &:hover:not(:disabled) {
      color: #fd4433;
    }
  }
}

.add-condition-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: vars.$mainColor;
  border: 1px dashed vars.$mainColor;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;

  &:hover:not(:disabled) {
    opacity: 0.7;
    font-weight: 500;
  }
}
