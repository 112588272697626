@use "../../vars";

.threat-model-details {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background-color: vars.$hover-table-row;

    .header {
        padding: 1.5rem 3.5rem 1.5rem 2rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        position: relative;

        .header-container {
            max-width: 1800px;
            margin: 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
        }

        .title-section {
            display: flex;
            align-items: center;
            gap: 1rem;
            min-width: 0;
            flex: 1;

            .title-icon {
                font-size: 1.5rem;
                color: vars.$mainColor;
                flex-shrink: 0;
            }

            h1 {
                font-size: 1.4rem;
                color: var(--mainTextColor);
                margin: 0;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .actions-section {
            display: flex;
            align-items: center;
            gap: 1rem;
            flex-shrink: 0;
            margin-right: 1rem;

            .version-dropdown {
                background-color: var(--secondaryElementsColor);
                border: 1px solid rgba(255, 255, 255, 0.1);
                color: var(--mainTextColor);
                padding: 0.5rem 1rem;
                border-radius: 6px;
                font-size: 0.9rem;
                width: 320px;
                cursor: pointer;
                transition: border-color 0.2s ease;

                &:hover {
                    border-color: vars.$accentColor;
                }

                option {
                    background-color: var(--secondaryElementsColor);
                    color: var(--mainTextColor);
                    padding: 8px;
                }
            }

            .button-group {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
        }

        .close-button {
            position: absolute;
            top: 50%;
            right: 0.75rem;
            transform: translateY(-50%);
            background: transparent;
            border: none;
            color: var(--mainTextColor);
            font-size: 2.5rem;
            cursor: pointer;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            transition: opacity 0.2s ease;
            z-index: 1;
            opacity: 0.6;

            &:hover {
                border-color: vars.$accentColor;
            }
        }
    }

    .content {
        display: flex;
        flex: 1;
        max-width: 1800px;
        margin: 0 auto;
        width: 100%;
        padding: 2rem;
        gap: 2rem;
        min-height: 0;
        overflow: hidden;

        .metadata {
            width: 350px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .metadata-container {
                background-color: var(--secondaryElementsColor);
                border-radius: 8px;
                padding: 1.5rem;
            }

            .info-banner {
                background-color: var(--secondaryElementsColor);
                border-radius: 8px;
                padding: 1rem 1.25rem;
                display: flex;
                align-items: flex-start;
                gap: 0.75rem;
                font-size: 0.95rem;
                line-height: 1.4;
                color: var(--mainTextColor);

                .info-icon {
                    flex-shrink: 0;
                    font-size: 1.25rem;
                    margin-top: 0.1rem;
                }

                p {
                    margin: 0;
                }
            }
        }

        .metadata-item {
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            &:last-child {
                margin-bottom: 0;
            }

            .metadata-label {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                color: vars.$secondary-text-color;
                font-size: 0.875rem;

                .icon {
                    color: vars.$mainColor;
                    font-size: 1.1rem;
                }
            }

            .metadata-value {
                color: var(--mainTextColor);
                font-size: 0.95rem;
                padding-left: 1.9rem;
            }
        }

        .model-content {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;

            .model-editor,
            .model-display {
                flex: 1;
                background-color: var(--secondaryElementsColor);
                color: var(--mainTextColor);
                padding: 1.25rem;
                border-radius: 8px;
                font-size: 0.95rem;
                line-height: 1.6;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-track {
                    background: rgba(0, 0, 0, 0.2);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 4px;
                }
            }

            .model-display {
                white-space: pre-wrap;
                word-wrap: break-word;
            }

            .model-editor {
                font-family: monospace;
                resize: none;

                &:focus {
                    border-color: rgba(114, 222, 255, 0.5);
                    outline: none;
                    box-shadow: 0 0 0 3px rgba(114, 222, 255, 0.1);
                }
            }
        }
    }

    .action-button {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 0.875rem;
        transition: all 0.2s ease;
        border: 1px solid transparent;

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
            pointer-events: none;
        }

        &.primary {
            background-color: vars.$accentColor;
            border: 1px solid transparent;
            color: white;

            &:hover:not(:disabled) {
                background-color: vars.$accent-color-dark;
            }
        }

        &.cancel {
            background-color: vars.$secondary-color-dark;
            color: vars.$main-text-color;
            border: none;

            &:hover {
                background-color: vars.$hover-dark-button;
            }
        }

        &.feedback {
            background-color: vars.$secondary-color-dark;
            color: vars.$main-text-color;
            border: none;

            &:hover {
                background-color: vars.$hover-dark-button;
            }

            &.liked {
                background-color: #1EB980;
                border-color: transparent;
                color: white;

                &:hover:not(:disabled) {
                    background-color: darken(#1EB980, 10%);
                }
            }

            &.disliked {
                background-color: #fd4433;
                border-color: transparent;
                color: white;

                &:hover:not(:disabled) {
                    background-color: darken(#fd4433, 10%);
                }
            }
        }
    }
}