.applicationItem {
  padding: 10px;
  border-style: solid;

  div {
    padding: 5px;
  }
}

.applicationId {
  cursor: pointer;

  &:hover {
    color: #75b475;
  }
}

a:hover{
  color: #92F2CC;
}

.appIdCopied {
  color: #75b475;
}

.iconCopyAppId {
  position: absolute;
  top: 0.1rem;
  left: 0;
  color: #75b475;
}

.appId {
  position: relative;

  .appIdCopied {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    font-size: 0.75rem;
  }
}

.policyComplianceStatus {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  font-weight: bold;

  &.PASSED {
    background-color: rgba(30, 185, 128, 0.2);
    color: #1EB980;
  }

  &.DID_NOT_PASS {
    background-color: rgba(253, 68, 51, 0.2);
    color: #fd4433;
  }

  &.CONDITIONAL_PASS {
    background-color: rgba(255, 207, 68, 0.2);
    color: #FFCF44;
  }

  &.NOT_ASSESSED {
    background-color: rgba(128, 128, 128, 0.2);
    color: grey;
  }

  &.DETERMINING {
    background-color: rgba(128, 128, 128, 0.2);
    color: grey;
  }

  svg {
    margin-right: 0.25rem;
  }
}