@use "../vars";

.form-control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.0rem;

  label {
    margin-right: 2.0rem;
    width: 25%;
    min-width: 100px;
    padding-top: 0.75rem;
  }

  input {
    flex-grow: 1;
    line-height: 2.0rem;

    font-size: 1.0rem;

    &[type="submit"], &[type="button"] {
      flex-grow: 0;
    }
  }

  div {
    flex-grow: 1;
  }

  select {
    flex-grow: 1;
    line-height: 2.0rem;
    font-size: 1.0rem;
    padding: 1.0rem 0.5rem;

    background: #444;
    border: none;
    box-shadow: 1px 1px 1px 1px #3a3a3a;
    color: vars.$main-text-color;
    cursor: pointer;
  }
}