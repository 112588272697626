@use "../../vars";

#loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.asset-record {
    font-weight: bold;
    max-width: 1500px;
    margin: 0 auto;
    padding: 2rem;

    .asset-header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 2rem;

        svg {
            color: #fff;
        }

        h1 {
            font-size: 1.75rem;
            font-weight: 600;
            color: #fff;
        }
    }

    .asset-content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.section-card {
    background: vars.$secondary-elements-color;
    border-radius: 15px;
    overflow: hidden;

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.5rem;
        cursor: pointer;

        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: vars.$main-text-color;
        }
    }

    .section-content {
        padding: 0 1.5rem 1.5rem;
    }
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    color: var(--mainTextColor);

    .info-item {
        background: vars.$hover-table-row;
        padding: 1rem;
        border-radius: 0.75rem;

        .label {
            display: block;
            font-size: 0.875rem;
            font-weight: bold;
            color: vars.$secondary-text-color;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }

        .value {
            color: var(--mainTextColor);
            word-break: break-word;
            line-height: 1.5;
        }
    }
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .tag {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.875rem;
    }
}

.compliance-status {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    
    .status-icon {
        font-size: 1.5rem;

        &.passed { color: #1EB980; }
        &.failed { color: #fd4433; }
        &.warning { color: #FFCF44; }
        &.neutral { color: #9e9e9e; }
    }

    span {
        color: var(--mainTextColor);
        font-size: 0.875rem;
        font-weight: bold;
    }
}

.severity-metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;

    .metric {
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        border: 1px solid transparent;

        &.critical {
            background: rgba(239, 68, 68, 0.1);
            color: #fe0101;
        }
        
        &.high {
            background: rgba(251, 146, 60, 0.1);
            color: #ff4a02;
        }
        
        &.medium {
            background: rgba(245, 158, 11, 0.1);
            color: #ff6f00;
        }
        
        &.low {
            background: rgba(252, 211, 77, 0.1);
            color: #ff9500;
        }
    }

    a {
        text-decoration: none;
        transition: opacity 0.2s ease, transform 0.2s ease;

        &:hover {
            opacity: 0.7;
        }
    }
}

.app-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .action-button {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        font-size: 0.9rem;
        font-weight: bold;
        text-decoration: none;
        border: none;
        cursor: pointer;
        background: vars.$accentColor;
        color: white;

        svg {
            font-size: 1.25rem;
        }

        &:disabled {
            background: rgba(108, 117, 125, 0.5);
            cursor: not-allowed;
        }
    }
}

.scan-results-container {
    margin-top: 2rem;

    table {
        width: 100%;
        border-collapse: collapse;
        background: vars.$secondary-elements-color;
        border-radius: 1rem;
        overflow: hidden;

        th {
            background: rgba(0, 0, 0, 0.2);
            text-align: left;
            padding: 1rem;
            color: vars.$accentColor;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }

        td {
            padding: 1rem;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            color: #fff;

            &:first-child {
                font-weight: 500;
            }
        }
    }
}

.scans-list {
    margin-top: 1.5rem;
}

@media (max-width: 768px) {
    .asset-record {
        padding: 1rem;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .app-actions {
        flex-direction: column;
        
        .action-button {
            width: 100%;
            justify-content: center;
        }
    }

    .severity-metrics {
        .metric {
            flex: 1;
            justify-content: center;
        }
    }
}