.settings-page {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.settings-section {
  background: var(--secondaryElementsColor);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  h2 {
    color: var(--mainTextColor);
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }

  .application-table-link {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
    text-decoration: none;
  }
}

.theme-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.force-label {
  color: var(--mainTextColor);
  opacity: 0.8;
  font-size: 1rem;
}

.force-switch {
  position: relative;
  width: 64px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
  padding: 2px;
  border: none;
  background: #1a1a1a;

  &.light {
    .switch-background {
      background: radial-gradient(circle at 30% 50%, #4287f5 0%, #1a1a1a 70%);
      
      &::after {
        opacity: 0.3;
        background: linear-gradient(45deg, #4287f5, transparent);
      }
    }

    .force-symbol {
      background: #ffffff;
      color: #4287f5;
    }
  }

  &.dark {
    .switch-background {
      background: radial-gradient(circle at 70% 50%, #a50202 0%, #1a1a1a 70%);
      
      &::after {
        opacity: 0.3;
        background: linear-gradient(-45deg, #310303, transparent);
      }
    }

    .force-symbol {
      background: #270000;
      color: #ff2323;
    }
  }
}

.switch-background {
  position: absolute;
  inset: 0;
  border-radius: 16px;
  transition: all 0.3s ease;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transition: all 0.3s ease;
  }
}

.force-symbol {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  svg {
    width: 16px;
    height: 16px;
    transition: all 0.3s ease;
  }
}

.stars {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 1.5px;
  height: 1.5px;
  background: white;
  border-radius: 50%;
  opacity: 0;
  animation: twinkle 3s infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 0; }
  50% { opacity: 0.8; }
}