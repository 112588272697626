@use "../../vars";

#dashboard {
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: center;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.dashboard-stats {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 1800px;
    margin-bottom: 2rem;

    .stat-card {
        background: vars.$secondary-elements-color;
        border-radius: 15px;
        padding: 1.5rem;

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .title {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                font-size: 1.25rem;
                font-weight: 600;
                color: vars.$main-text-color;

                svg {
                    color: vars.$mainColor;
                }
            }
        }

        .card-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            align-items: flex-start;
            padding: 0.5rem;

            .main-stat {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;

                .number {
                    font-size: 2.5rem;
                    font-weight: 700;
                    color: var(--mainTextColor);
                    line-height: 1;
                }

                .label {
                    font-size: 0.9rem;
                    color: vars.$secondary-text-color;
                }
            }

            .stat-breakdown {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 0.5rem;

                .stat-item {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 0.95rem;
                    color: vars.$secondary-text-color;

                    &.liked {
                        svg {
                            color: #1EB980;
                        }
                    }

                    &.disliked {
                        svg {
                            color: #FD4433;
                        }
                    }

                    &.neutral {
                        svg {
                            color: #FFCF44;
                        }
                    }

                    .type-indicator {
                        min-width: 10px;
                        width: 10px;
                        min-height: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #60a5fa;
                        flex-shrink: 0;
                        display: block;
                    }

                    &:nth-child(1) .type-indicator {
                        background: #1EB980;
                    }

                    &:nth-child(2) .type-indicator {
                        background: #FFCF44;
                    }

                    &:nth-child(3) .type-indicator {
                        background: #60a5fa;
                    }

                    &:nth-child(4) .type-indicator {
                        background: #6b7280;
                    }

                    // SECBUG cards
                    &:nth-child(1) .scan-type-indicator {
                        background: #60a5fa;
                    }

                    &:nth-child(2) .scan-type-indicator {
                        background: #FFCF44;
                    }

                    &:nth-child(3) .scan-type-indicator {
                        background: #1EB980;
                    }

                    &:nth-child(4) .scan-type-indicator {
                        background: #6b7280;
                    }
                }
            }
        }

        &[style*="grid-column"] {
            .card-content {
                display: block !important;
                padding: 1rem 0;

                .chart-container {
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
}