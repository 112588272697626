@use "../../vars";

.date-popup {
    background: white;
    padding: 5px;
    border-radius: 0.5rem;
}

.jira-statistic-filter {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    div.btn-group, button {
        margin: 7px;
    }

    div.selectall-multiselect {
        min-width: 300px;
    }

    .filter-container {
        position: relative;
        display: inline-block;
    }

    .filter-dropdown-btn {
        background-color: vars.$accentColor;
        min-width: 150px;
        text-align: left;
        border-radius: 24px;
        
        &:active, &:focus {
            background-color: vars.$accent-color-dark;
        }
    }

    .filter-options {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        background: white;
        border-radius: 24px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        min-width: 200px;
        margin-top: 4px;
        overflow: hidden;

        .btn-group-vertical {
            display: block;
            width: 100%;

            .btn {
                display: block;
                width: 100%;
                text-align: left;
                border: none;
                border-radius: 0;
                padding: 8px 12px;
                margin: 0;
                
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .btn-check:checked + .btn-outline-primary {
        background-color: vars.$accentColor;
        color: white !important;
    }

    .btn-check + .btn-outline-primary {
        color: vars.$secondary-text-color !important;
    }

    .btn-check:active + .btn-outline-primary {
        background-color: vars.$accent-color-dark;
    }

    > button.btn {
        background-color: vars.$accentColor;
        
        &:active, &:focus {
            background-color: vars.$accent-color-dark;
        }
    }

    .btn-group {
        .btn-outline-primary {
            border-color: vars.$accentColor;
        }
    }
}