@use "../../vars";

.secrets-scanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;

    &.initial-state {
        justify-content: center;
    }

    .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 500px;
        margin-bottom: 30px;
        position: relative;

        .filter-container {
            position: static;
        }

        .filter-button {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            background-color: vars.$secondaryColor;
            color: var(--mainTextColor);
            border: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;

            &:hover {
                background-color: vars.$secondary-color-dark;
            }
        }

        .filter-popover {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: vars.$secondaryColor;
            border-radius: 15px;
            z-index: 10;
            min-width: 180px;
            margin-top: 10px;
            overflow: hidden;
            padding: 10px 0;
        }

        .filter-section {
            h3 {
                color: var(--mainTextColor);
                font-size: 14px;
                margin: 0;
                padding: 5px 15px;
                font-weight: normal;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            .checkbox-container {
                display: flex;
                align-items: center;
                padding: 8px 15px;

                input[type="checkbox"] {
                    margin-right: 8px;
                    -webkit-appearance: none;
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    outline: none;
                    cursor: pointer;
                    position: relative;

                    &:checked {
                        background-color: vars.$accentColor;
                        border-color: vars.$accentColor;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #fff;
                        }
                    }

                    &:not(:checked) {
                        border-color: vars.$secondary-text-color;
                    }
                }

                span {
                    color: var(--mainTextColor);
                    font-size: 14px;
                }
            }
        }

        .filter-option {
            display: block;
            width: 100%;
            padding: 8px 15px;
            text-align: left;
            background: none;
            border: none;
            cursor: pointer;
            color: var(--mainTextColor);
            transition: background-color 0.2s ease;
            font-size: 14px;

            &:hover {
                background-color: vars.$secondary-color-dark;
            }

            &.selected {
                background-color: var(--secondaryColorDark);
                font-weight: bold;
            }
        }

        .ignore-filter-input {
            margin: 5px 15px;
            padding: 8px;
            border: none;
            border-radius: 10px;
            background-color: var(--secondaryColorDark);
            color: var(--mainTextColor);
            font-size: 14px;

            &::placeholder {
                color: #aaa;
            }
        }

        .search-input {
            flex-grow: 1;
            padding: 10px 15px 10px 50px;
            font-size: 16px;
            border: none;
            border-radius: 8px;
            outline: none;
        }

        .search-button {
            margin-left: 10px;
            width: 50px;
            height: 50px;
            font-size: 20px;
            background-color: vars.$accentColor;
            color: white;
            border: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: vars.$accent-color-dark;
            }
        }
    }

    .no-results,
    .error {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        color: #666;
    }

    .error {
        color: #d32f2f;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
    }
}

.action-buttons {
    display: flex;
    gap: 9px;

    .github-link-button,
    .secret-toggle-button,
    .jira-ticket-button {
        color: var(--mainTextColor) !important;

        &:hover {
            color: vars.$mainColor !important;
        }
    }
}

.secrets-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 2rem;

    .stat-card {
        background: vars.$secondary-elements-color;
        border-radius: 15px;
        padding: 1.5rem;

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            .title {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                font-size: 1.25rem;
                font-weight: 600;
                color: vars.$main-text-color;

                svg {
                    color: vars.$mainColor;
                }
            }
        }

        .card-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            align-items: flex-start;
            padding: 0.5rem;

            .main-stat {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;

                .number {
                    font-size: 2.5rem;
                    font-weight: 700;
                    color: vars.$main-text-color;
                    line-height: 1;
                }

                .label {
                    font-size: 0.9rem;
                    color: vars.$secondary-text-color;
                }

                .chart-container {
                    height: 120px;
                    margin-top: 1rem;
                }
            }

            .stat-breakdown {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 0.5rem;

                .stat-item {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 0.95rem;
                    color: vars.$secondary-text-color;

                    .type-indicator {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #60a5fa;
                    }

                    &:nth-child(1) .type-indicator {
                        background: #1EB980;
                    }

                    &:nth-child(2) .type-indicator {
                        background: #FFCF44;
                    }

                    &:nth-child(3) .type-indicator {
                        background: #60a5fa;
                    }

                    &:nth-child(4) .type-indicator {
                        background: #6b7280;
                    }

                    &:nth-child(5) .type-indicator {
                        background: #9061F9;
                    }
                }
            }
        }
    }
}