@use "../../vars";

body.modal-open {
    overflow: hidden;
}

.MuiIconButton-root {
    color: var(--mainTextColor) !important;

    &:hover {
        color: vars.$mainColor !important;
    }

    &+.MuiIconButton-root {
        margin-left: 5px;
    }
}

.clear-filters-section {
    padding: 0px 15px;

    .clear-all-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        padding: 0.5rem 0.875rem;
        border-radius: 8px;
        font-weight: bold;
        font-size: 0.875rem;
        transition: all 0.2s ease;
        border: 1px solid vars.$accentColor;
        background-color: transparent;
        color: vars.$main-text-color;

        &:hover:not(:disabled) {
            background: vars.$accent-color-dark;
            border: 1px solid vars.$accent-color-dark;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        svg {
            font-size: 16px;
        }
    }
}

// Details page
.asset-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .header-content {
        display: flex;
        align-items: center;
        gap: 1rem;

        .app-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            svg {
                color: vars.$mainColor;
            }
        }
    }

    .title-badges {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h1 {
            margin: 0;
            font-size: 1.5rem;
            line-height: 1.2;
            display: flex;
            align-items: center;
            color: vars.$main-text-color;
        }

        .badges {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }

        .environment-badge {
            display: inline-flex;
            align-items: center;
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            font-size: 0.9rem;
            font-weight: bold;

            &.production {
                background-color: rgba(58, 169, 217, 0.2);
                color: #3aa9d9;
            }

            &.flagship {
                background-color: rgba(30, 185, 128, 0.2);
                color: #1EB980;
            }

            &.draft {
                background-color: rgba(128, 128, 128, 0.2);
                color: grey;
            }

            &.synced {
                padding: 7.5px;
                background-color: rgba(30, 185, 128, 0.2);
                color: #1EB980;
            }

            &.not-synced {
                padding: 7.5px;
                background-color: rgba(253, 68, 51, 0.2);
                color: #fd4433;
            }

            &.scanhub {
                background-color: rgba(111, 66, 193, 0.2);
                color: #a180de;
            }
        }
    }

    .header-actions {
        display: flex;
        gap: 0.75rem;

        button {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            padding: 0.5rem 0.875rem;
            border-radius: 8px;
            font-weight: bold;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            border: 1px solid transparent;

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &.delete-btn {
                background-color: #fd4433;
                color: white;

                &:hover:not(:disabled) {
                    background-color: darken(#fd4433, 10%);
                }
            }

            &.draft-btn {
                background-color: vars.$accentColor;
                color: white;

                &:hover:not(:disabled) {
                    background-color: vars.$accent-color-dark;
                }
            }

            &.save-btn {
                background-color: vars.$accentColor;
                color: white;

                &:hover:not(:disabled) {
                    background-color: vars.$accent-color-dark;
                }

                &.production {
                    background: linear-gradient(90deg, #3aa9d9 0%, #1EB980 100%);

                    &:hover:not(:disabled) {
                        background: linear-gradient(90deg, darken(#3aa9d9, 5%) 0%, darken(#1EB980, 5%) 100%);
                    }
                }
            }
        }
    }
}

.editable-field {
    position: relative;
    width: 100%;

    .display-value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        min-height: 24px;
        padding: 2px 4px;
        border-radius: 4px;
        overflow: hidden;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
        }

        .edit-icon {
            cursor: pointer;
            color: vars.$secondary-text-color;
            font-size: 1rem;
            transition: all 0.2s ease;

            &:hover {
                color: vars.$mainColor;
            }
        }
    }

    input {
        width: 100%;
        background-color: vars.$secondary-elements-color;
        border: 1px solid rgba(114, 222, 255, 0.5);
        border-radius: 4px;
        padding: 2px 4px;
        color: var(--mainTextColor);
        font-size: inherit;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px rgba(114, 222, 255, 0.2);
        }
    }
}

.description-field {
    .display-value span {
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.canonical-id-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 600;

    &.disabled {
        opacity: 0.5;
    }
}

.status-indicators {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    .status-dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        font-weight: bold;

        &.production {
            background-color: rgba(58, 169, 217, 0.2);
            color: #3aa9d9;
        }

        &.flagship {
            background-color: rgba(30, 185, 128, 0.2);
            color: #1EB980;
        }

        &.draft {
            background-color: rgba(128, 128, 128, 0.2);
            color: grey;
        }

        &.no-jira {
            background-color: rgba(253, 68, 51, 0.2);
            color: #fd4433;
        }
    }
}

.error-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    animation: fadeIn 0.3s ease-out;
    background-color: rgba(253, 68, 51, 0.1);
    border: 1px solid rgba(253, 68, 51, 0.3);
    color: #fd4433;

    .error-content {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }
}

.success-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.4;
    animation: fadeIn 0.3s ease-out;
    background-color: rgba(30, 185, 128, 0.1);
    border: 1px solid rgba(30, 185, 128, 0.3);
    color: #1EB980;
}

.required-indicator {
    color: #ff4d4f;
    margin-left: 2px;
}

.column-menu-paper {
    background-color: vars.$secondaryColor !important;
    border-radius: 15px !important;
    min-width: 200px !important;
    padding: 10px 0;
    margin-top: 10px;

    .filter-section {
        h3 {
            color: var(--mainTextColor);
            font-size: 14px;
            margin: 0;
            padding: 5px 15px;
            font-weight: normal;
        }

        &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .checkbox-container {
            display: flex;
            align-items: center;
            padding: 8px 15px;

            &:hover {
                background-color: vars.$secondary-color-dark !important;
            }

            input[type="checkbox"] {
                margin-right: 8px;
                -webkit-appearance: none;
                appearance: none;
                width: 18px;
                height: 18px;
                border: 2px solid #fff;
                border-radius: 50%;
                border-radius: 4px;
                outline: none;
                cursor: pointer;
                position: relative;

                &:checked {
                    background-color: vars.$accentColor;
                    border-color: vars.$accentColor;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 6px;
                        width: 4px;
                        height: 10px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }

                &:not(:checked) {
                    border-color: vars.$secondary-text-color;
                }
            }

            span {
                color: var(--mainTextColor);
                font-size: 14px;
            }
        }
    }
}

.filter-actions {
    display: flex;
}

.checkbox-container {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid #fff;
        border-radius: 50%;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        position: relative;

        &:checked {
            background-color: vars.$accentColor;
            border-color: vars.$accentColor;

            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 6px;
                width: 4px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        &:not(:checked) {
            border-color: vars.$secondary-text-color;
        }
    }

}

.bulk-edit-float {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondaryColor);
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding: 12px 18px;
    animation: slideUp 0.3s ease-out;

    .bulk-edit-count {
        font-weight: bold;
        margin-right: 20px;
        color: var(--mainTextColor);
    }

    .bulk-edit-actions {
        display: flex;
        gap: 10px;

        button {
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 8px 16px;
            border-radius: 8px;
            font-weight: bold;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            border: 1px solid transparent;

            &.bulk-edit-btn {
                background-color: vars.$accentColor;
                color: white;

                &:hover {
                    background-color: vars.$accent-color-dark;
                }
            }

            &.bulk-edit-cancel {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }
        }
    }
}

.notification-toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1EB980;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: white;
    padding: 12px 18px;
    border-radius: 8px;
    max-width: 450px;
    animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    15% {
        opacity: 1;
        transform: translateY(0);
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translate(-50%, 20px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}