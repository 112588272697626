@use "../../vars";

.security-metadata {
    font-family: Arial, sans-serif;

    .app-details-card {
        background-color: vars.$secondary-elements-color;
        border-radius: 1rem;
        padding: 1.5rem;
        margin: 1rem 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        width: 100%;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
        
        h2 {
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
            color: #fff;
            
            svg {
                margin-right: 0.5rem;
            }
        }

        .details-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            margin-bottom: 2rem;
        }

        .details-column {
            flex: 1;
            min-width: 250px;
        }
        
        .details-element {
            margin-bottom: 1.5rem;
            
            .label {
                font-size: 0.9rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
                color: #9e9e9e;
                text-transform: uppercase;
            }

            div:not(.label) {
                font-size: 1rem;
                color: #fff;
                word-break: break-word;
            }
        }

        .exemptions-section {
            margin-top: 2rem;
            
            h3 {
                color: #fff;
                margin-bottom: 1rem;
            }

            .exemptions-grid {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
            }

            .exemption-item {
                background-color: rgba(255, 255, 255, 0.05);
                border-radius: 0.5rem;
                padding: 1rem;
                flex: 1;
                min-width: 200px;

                .details-element {
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}