@use "../../vars";

.statistic-pane {
  .chart-title {
    margin: 5px;
    
    h4 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }

  .chart-container {
    border-radius: 8px;
    padding: 1.5rem;
  }

  .chart-header {
    margin-bottom: 1.5rem;
    
    h5 {
      font-size: 1.2rem;
      margin: 0;
    }
  }

  .chart-pair {
    .one-chart {
      width: 100%;
      border: 1px solid #444;
      border-radius: 8px;
      padding: 1.5rem;
    }
  }
}

.custom-tooltip {
  background: rgba(32, 32, 32, 0.95);
  border: 1px solid #444;
  border-radius: 4px;
  padding: 1rem;

  .tooltip-label {
    margin-bottom: 0.5rem;
    color: #fff;
  }

  p {
    margin: 0.25rem 0;
    font-size: 0.9rem;
  }
}

.jira-statistic-filter {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .btn-group {
    border-radius: 8px;
    overflow: hidden;

    &[role="group"][aria-label="Basic radio toggle button group"] {
      position: relative;
      background-color: vars.$secondary-color-dark;

      .btn {
        color: #fff;
      }
    }
  }

  .btn {
    font-size: 0.9rem;
    padding: 9px 12px;
    border-radius: 8px;
    border: none;
  }
}

.csv-button {
  border-radius: 8px;
  padding: 9px 12px;
  font-size: 0.9rem;
  border: none;
  background-color: vars.$accentColor;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .statistic-pane {
    .one-chart {
      width: 100%;
    }
  }

  .jira-statistic-filter {
    flex-direction: column;
    align-items: flex-start;
  }

  .csv-button {
    margin-left: 0;
    margin-top: 10px;
  }
}