@use "../../vars";

.reporting-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    &__metrics-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.5rem;
        margin-bottom: 2rem;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    &__charts-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        margin-bottom: 2rem;

        .col-span-2 {
            grid-column: span 2;

            @media (max-width: 1400px) {
                grid-column: span 1;
            }

            .chart-container {
                height: 500px;
            }
        }

        @media (max-width: 1400px) {
            grid-template-columns: 1fr;
        }
    }

    &__chart-cell {
        position: relative;
        background: vars.$secondary-elements-color;
        border-radius: 10px;
        padding: 1.5rem;
        min-height: 550px;

        &.reduction-goal-chart {
            .chart-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .chart-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .chart-title {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                flex: 1;
                min-width: 0; // Important for text overflow handling

                svg {
                    color: var(--mainColor);
                    font-size: 1.25rem;
                    flex-shrink: 0;
                }

                h5 {
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: var(--main-text-color);
                    margin: 0;
                    white-space: nowrap;
                    flex-shrink: 0;
                }
            }

            .filter-button {
                background: none;
                border: none;
                padding: 0.5rem;
                cursor: pointer;
                color: var(--secondary-text-color);
                display: flex;
                align-items: center;
                flex-shrink: 0;
                gap: 0.4rem;
                font-weight: bold;

                &:hover {
                    color: var(--mainColor);
                }

                &.active {
                    color: var(--mainColor);
                }
            }
        }

        .chart-container {
            position: relative;
            height: calc(100% - 60px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.csv-export {
            .chart-container {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 2rem;

                .export-content {
                    max-width: 440px;

                    p {
                        color: var(--secondary-text-color);
                        margin-bottom: 1.5rem;
                        font-size: 0.875rem;
                        line-height: 1.5;

                        &:last-of-type {
                            margin-bottom: 2rem;
                        }
                    }

                    button.btn-primary {
                        background-color: vars.$accentColor;
                        border: none;
                        color: white;
                        padding: 0.625rem 1.25rem;
                        border-radius: 8px;
                        font-size: 0.875rem;
                        font-weight: bold;

                        &:hover {
                            background-color: vars.$accent-color-dark;
                        }

                        &:active {
                            transform: translateY(0);
                        }
                    }

                    .export-btn {
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        gap: 0.4rem;
                        padding: 0.5rem 0.875rem;
                        border-radius: 8px;
                        font-weight: bold;
                        font-size: 0.875rem;
                        transition: all 0.2s ease;
                        border: 1px solid transparent;
                        background-color: vars.$accentColor;
                        color: white;

                        &:hover:not(:disabled) {
                            background-color: vars.$accent-color-dark;
                        }
                    }
                }
            }
        }
    }

    &__metric-card {
        background: vars.$secondary-elements-color;
        border-radius: 10px;
        padding: 1.5rem;

        .card-header {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-size: 1.2rem;
            font-weight: 600;
            color: vars.$main-text-color;
            margin-bottom: 0.5rem;

            svg {
                color: vars.$mainColor;
                font-size: 1.5rem;
            }
        }

        .card-content {
            .value {
                font-size: 2rem;
                font-weight: 700;
                color: vars.$main-text-color;
                margin-bottom: 0.25rem;
            }

            .label {
                font-size: 0.875rem;
                color: vars.$secondary-text-color;
                margin-bottom: 0.75rem;
            }

            .change {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 0.875rem;
                font-weight: 500;

                svg {
                    font-size: 1rem;
                }

                &.positive {
                    color: #1EB980;
                }

                &.negative {
                    color: #FD4433;
                }
            }
        }
    }

    :global {
        .recharts-tooltip-wrapper {
            background: vars.$secondary-elements-color;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        }

        .recharts-default-tooltip {
            background-color: vars.$secondary-elements-color !important;
            border: 1px solid vars.$mainColor !important;
            border-radius: 8px;
            padding: 1rem;

            .recharts-tooltip-label {
                color: vars.$main-text-color;
                font-weight: 600;
                margin-bottom: 0.5rem;
            }

            .recharts-tooltip-item {
                color: vars.$secondary-text-color;
                padding: 0.25rem 0;
            }
        }

        .recharts-cartesian-grid-horizontal line,
        .recharts-cartesian-grid-vertical line {
            stroke: rgba(255, 255, 255, 0.1);
        }

        .recharts-xAxis .recharts-cartesian-axis-tick-value,
        .recharts-yAxis .recharts-cartesian-axis-tick-value {
            fill: vars.$secondary-text-color;
            font-size: 0.875rem;
        }

        .recharts-legend-item-text {
            color: vars.$main-text-color !important;
        }

        .recharts-pie-label-text {
            fill: vars.$main-text-color;
        }

        .recharts-sector {
            stroke: none;
        }

        .recharts-legend-wrapper {
            padding-top: 1rem;
        }

        .recharts-default-legend {
            text-align: center !important;
        }
    }

    .vulnerability-analysis-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .chart-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            .chart-subtitle {
                font-size: 0.95rem;
                font-weight: 600;
                margin-bottom: 0.75rem;
                color: var(--main-text-color);
            }

            .chart-container {
                width: 100%;
                height: 350px;
            }
        }
    }
}

svg text.bar-label {
    fill: var(--mainTextColor) !important;
}