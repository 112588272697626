@use "../../vars";

.chart-filters {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--secondaryElementsColor);
    border-radius: 15px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    animation: slideIn 0.3s ease-out;

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        h3 {
            font-size: 1.125rem;
            font-weight: 600;
            margin: 0;
            color: var(--mainTextColor);
        }

        .chart-filters__close-btn {
            background: none;
            border: none;
            color: var(--secondaryTextColor);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            transition: all 0.2s ease;

            &:hover {
                color: var(--mainTextColor);
                opacity: 0.8;
            }
        }
    }

    &__body {
        flex: 1;
        display: flex;
        gap: 1.5rem;
        overflow: hidden;
        min-height: 0;

        &-left {
            overflow-y: auto;
            padding-right: 0.5rem;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 3px;
            }
        }

        &-right {
            flex: 1;
            overflow-y: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            align-content: start;
            align-items: start;
            padding-right: 0.5rem;
            position: relative;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 3px;
            }
        }
    }

    &__time-controls {
        h4 {
            font-size: 0.875rem;
            font-weight: 500;
            color: var(--secondaryTextColor);
            margin: 0 0 0.75rem;
        }

        .time-scale {
            margin-bottom: 1.5rem;

            &__options {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
            }

            &__btn {
                padding: 0.5rem 0.875rem;
                border-radius: 8px;
                background-color: vars.$secondary-color-dark;
                border: none;
                color: var(--mainTextColor);
                cursor: pointer;
                font-size: 0.875rem;
                transition: all 0.2s ease;

                &.active {
                    background-color: vars.$accentColor;
                    color: white;
                    border: none;
                }

                &:hover:not(.active) {
                    background-color: vars.$hover-dark-button;
                }

                &:hover.active {
                    background-color: vars.$accent-color-dark;
                }
            }
        }

        .date-range {
            &__presets {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
                margin-bottom: 1rem;

                .preset-btn {
                    padding: 0.5rem 0.875rem;
                    border-radius: 8px;
                    background-color: vars.$secondary-color-dark;
                    border: none;
                    color: var(--mainTextColor);
                    cursor: pointer;
                    font-size: 0.875rem;
                    transition: all 0.2s ease;
                    font-weight: 500;

                    &.active {
                        background-color: vars.$accentColor;
                        color: white;
                        border: none;
                    }

                    &:hover:not(.active) {
                        background-color: vars.$hover-dark-button;
                    }

                    &:hover.active {
                        background-color: vars.$accent-color-dark;
                    }
                }
            }

            &__inputs {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
            }

            .date-input {
                position: relative;

                svg {
                    position: absolute;
                    left: 1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    color: var(--secondaryTextColor);
                }

                input {
                    width: 100%;
                    padding: 0.75rem 1rem 0.75rem 2.5rem;
                    border: none;
                    border-radius: 8px;
                    background-color: vars.$secondary-color-dark;
                    color: var(--mainTextColor);
                    font-size: 0.875rem;

                    &:focus {
                        outline: none;
                        border-color: vars.$mainColor;
                    }
                }
            }
        }
    }

    .filter-section {
        background-color: vars.$secondary-color-dark;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        min-height: 3.5rem;
        transition: all 0.3s ease;
        height: fit-content;
        display: flex;
        flex-direction: column;
        position: relative;

        &.expanded {
            position: relative;
            z-index: 1;
            border: 1px solid vars.$mainColor;
            border-radius: 8px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .filter-section__content {
                position: absolute;
                top: calc(100% - 1px);
                left: -1px;
                right: -1px;
                display: flex;
                background-color: vars.$secondary-color-dark;
                border: 1px solid vars.$mainColor;
                border-top: none;
                border-radius: 0 0 8px 8px;
                z-index: 1;
            }
        }

        &.active {
            border-color: vars.$mainColor;
        }

        &__header {
            padding: 1rem;
            min-height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            user-select: none;

            h4 {
                font-size: 0.875rem;
                font-weight: 500;
                margin: 0;
                color: var(--mainTextColor);
                line-height: 1.2;
            }

            &-right {
                display: flex;
                align-items: center;
                gap: 0.75rem;

                .filter-count {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 0.75rem;
                    color: var(--mainTextColor);
                    border: 1px solid vars.$main-text-color;
                    padding: 0 0.5rem;
                    border-radius: 15px;
                    height: 1.5rem;
                    line-height: 1;

                    .clear-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: none;
                        border: none;
                        padding: 2px;
                        cursor: pointer;
                        color: var(--secondaryTextColor);
                        border-radius: 8px;
                        transition: all 0.2s ease;

                        &:hover {
                            background: vars.$hover-dark-button;
                            color: var(--mainTextColor);
                        }
                    }
                }

                svg {
                    color: var(--mainTextColor);
                    transition: transform 0.3s ease;

                    &.rotated {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__content {
            display: none;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.5rem;
            background-color: vars.$secondary-color-dark;
            position: relative;
        }

        &__search {
            position: relative;
            padding: 0 0.5rem;

            .search-icon {
                position: absolute;
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
                color: var(--secondaryTextColor);
                pointer-events: none;
            }

            input {
                width: 100%;
                padding: 0.5rem 1rem 0.5rem 2rem;
                border: 1px solid vars.$secondary-text-color;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 0.05);
                color: var(--mainTextColor);
                font-size: 0.875rem;

                &::placeholder {
                    color: var(--secondaryTextColor);
                }

                &:focus {
                    outline: none;
                    border-color: vars.$mainColor;
                }
            }
        }

        &__options {
            max-height: 200px;
            overflow-y: auto;
            margin-top: 0.5rem;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            .filter-option {
                padding: 0.75rem;
                cursor: pointer;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: all 0.2s ease;
                color: var(--mainTextColor);

                &:hover {
                    background: vars.$hover-dark-button;
                }

                &.selected {
                    color: vars.$mainColor;

                    .check-icon {
                        color: vars.$mainColor;
                    }
                }

                span {
                    font-size: 0.875rem;
                }

                &:first-child {
                    font-weight: 600;
                }
            }
        }
    }

    &__footer {
        margin-top: 1.5rem;
        padding-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        button {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            padding: 0.5rem 0.875rem;
            border-radius: 8px;
            border: 1px solid transparent;
            font-weight: bold;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            background-color: vars.$secondary-elements-color;
            color: var(--mainTextColor);
            cursor: pointer;

            &.btn-cancel {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;
                border: none;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }

            &.btn-apply {
                background-color: vars.$accentColor;
                border: none;
                color: white;

                &:hover {
                    background-color: vars.$accent-color-dark;
                }

                &:active {
                    transform: translateY(0);
                }
            }
        }
    }

    @media (max-width: 1200px) {
        &__body {
            flex-direction: column;

            &-left {
                width: 100%;
            }

            &-right {
                grid-template-columns: 1fr;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 1rem;

        &__time-controls {

            .time-scale__options,
            .date-range__presets {
                flex-direction: column;

                button {
                    width: 100%;

                    &:hover {
                        background-color: vars.$hover-dark-button;
                    }
                }
            }
        }

        &__footer {
            button {
                flex: 1;
            }
        }
    }
}