@use "../../vars";

.add-application-modal {

    .findings-section,
    .scan-requirements-section,
    .team-assignments-section,
    .devsecops-section {
        h3 {
            color: var(--mainTextColor);
            font-size: 1.4rem;
            margin: 2rem 0 1.5rem;
            font-weight: 600;

            &:first-child {
                margin-top: 0;
            }
        }

        h4 {
            color: vars.$secondary-text-color;
            font-size: 1.1rem;
            margin: 1.5rem 0 1rem;
            font-weight: 500;
        }
    }

    .scan-configs-section {
        .scan-type-container {
            background-color: vars.$secondary-elements-color;
            border-radius: 8px;
            padding: 1.5rem;
            margin-bottom: 2rem;
            border: 1px solid rgba(255, 255, 255, 0.1);

            .severity-grid {
                .grid-header {
                    display: grid;
                    grid-template-columns: 2fr 1fr 2fr;
                    padding: 0.75rem 1rem;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    color: vars.$secondary-text-color;
                }

                .grid-row {
                    display: grid;
                    grid-template-columns: 2fr 1fr 2fr;
                    padding: 0.75rem 1rem;
                    align-items: center;

                    .severity-column {
                        color: var(--mainTextColor);
                    }

                    .enabled-column {
                        input[type="checkbox"] {
                            width: 18px;
                            height: 18px;
                            border: 2px solid #fff;
                            border-radius: 4px;
                            outline: none;
                            cursor: pointer;
                            position: relative;
                            -webkit-appearance: none;
                            appearance: none;

                            &:checked {
                                background-color: vars.$accentColor;
                                border-color: vars.$accentColor;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 2px;
                                    left: 6px;
                                    width: 4px;
                                    height: 10px;
                                    border: solid white;
                                    border-width: 0 2px 2px 0;
                                    transform: rotate(45deg);
                                }
                            }

                            &:not(:checked) {
                                border-color: vars.$secondary-text-color;
                            }
                        }
                    }

                    .grace-column {
                        select {
                            width: 120px;
                            padding: 0.5rem;
                            border-radius: 6px;
                            border: 1px solid rgba(255, 255, 255, 0.15);
                            background-color: vars.$secondary-color-dark;
                            color: var(--mainTextColor);
                            font-size: 0.95rem;
                            cursor: pointer;

                            &:disabled {
                                opacity: 0.5;
                                cursor: not-allowed;
                            }

                            &:focus {
                                border-color: rgba(114, 222, 255, 0.5);
                                outline: none;
                                box-shadow: 0 0 0 3px rgba(114, 222, 255, 0.1);
                            }
                        }
                    }
                }
            }
        }

        .severity-label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-weight: bold;

            span {
                color: var(--mainTextColor);
            }
        }
    }

    .form-group {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 1rem;
        }

        label {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 0.5rem;
            display: block;
            color: var(--mainTextColor);

            &.required {
                display: flex;
                align-items: center;
                gap: 0.25rem;

                .required-indicator {
                    color: #ff4d4f;
                    margin-left: 2px;
                }
            }
        }

        .modern-input,
        textarea,
        input[type="date"],
        select {
            width: 100%;
            padding: 0.75rem;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background-color: vars.$secondary-elements-color;
            color: var(--mainTextColor);
            font-size: 1rem;
            line-height: 1.5;
            transition: all 0.2s ease;

            &:focus {
                border-color: rgba(114, 222, 255, 0.5);
                outline: none;
                box-shadow: 0 0 0 3px rgba(114, 222, 255, 0.1);
            }

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &::placeholder {
                font-size: 0.95rem;
                color: vars.$secondary-text-color;
            }
        }

        select.modern-input {
            cursor: pointer;
            appearance: none;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 0.7rem center;
            background-size: 1em;
            padding-right: 2.5rem;
        }

        textarea {
            resize: vertical;
            min-height: 80px;
        }

        input[type="date"] {
            padding: 0.4rem 0.5rem;
            height: 38px;

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
                cursor: pointer;
            }
        }
    }

    .compliance-row {
        margin-bottom: 1.5rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        align-items: start;

        input {
            margin-bottom: 0;
        }
    }

    .button-group {
        max-width: 800px;
        margin: 2rem auto;
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        button {
            padding: 0.5rem 0.875rem;
            border-radius: 8px;
            font-weight: bold;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            min-width: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &.cancel-btn {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;
                border: none;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }

            &.submit-btn {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: vars.$accentColor;
                border: none;
                color: white;

                &:hover:not(:disabled) {
                    background-color: vars.$accent-color-dark;
                }
            }

            &.previous-btn {
                background-color: vars.$secondary-color-dark;
                color: vars.$main-text-color;
                border: none;

                &:hover {
                    background-color: vars.$hover-dark-button;
                }
            }
        }
    }

    .error-message,
    .success-message {
        padding: 0.75rem 1rem;
        border-radius: 8px;
        margin-top: 1rem;

        div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }

    .error-message {
        background: rgba(255, 77, 79, 0.1);
        border: 1px solid rgba(255, 77, 79, 0.2);
        color: #ff4d4f;
    }

    .success-message {
        background-color: rgba(30, 185, 128, 0.1);
        border: 1px solid rgba(30, 185, 128, 0.3);
        color: #1EB980;
    }

    @keyframes fadeSlideUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.asset-selector {
    position: relative;

    .selected-assets {
        padding: 0.75rem;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: var(--secondaryElementsColor);
        color: var(--mainTextColor);
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            transition: transform 0.3s ease;

            &.rotated {
                transform: rotate(180deg);
            }
        }
    }

    .asset-dropdown {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        background-color: var(--secondaryColorDark);
        border: 1px solid var(--mainColor);
        border-radius: 8px;
        z-index: 10;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

        .filter-section__search {
            position: relative;
            padding: 0.5rem;

            .search-icon {
                position: absolute;
                left: 1rem;
                top: 50%;
                transform: translateY(-50%);
                color: var(--secondaryTextColor);
                pointer-events: none;
            }

            input {
                width: 100%;
                padding: 0.5rem 1rem 0.5rem 2rem;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 0.05);
                color: var(--mainTextColor);
                font-size: 0.875rem;

                &::placeholder {
                    color: var(--secondaryTextColor);
                }

                &:focus {
                    outline: none;
                    border-color: var(--mainColor);
                }
            }
        }

        .asset-options {
            max-height: 250px;
            overflow-y: auto;
            padding: 0 0.5rem 0.5rem;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }

            .asset-option {
                padding: 0.75rem;
                cursor: pointer;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: all 0.2s ease;
                color: var(--mainTextColor);

                &:hover {
                    background: rgba(255, 255, 255, 0.05);
                }

                &.selected {
                    color: var(--mainColor);

                    .check-icon {
                        color: var(--mainColor);
                    }
                }

                span {
                    font-size: 0.875rem;
                }
            }
        }
    }
}