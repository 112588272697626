@use "../vars";

#root {
  height: 100vh;
  background-color: var(--bodyBg);
  color: var(--bodyColor);
}

.main-content {
  flex: 1;
  padding: 1rem 1rem 1rem calc(84px + 1rem);
  overflow-y: auto;
}